/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     // icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // },
    {
        id   : 'sponsor',
        title: 'Sponsor Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/sponsor'
    },
    {
        id   : 'organizer',
        title: 'Organizer Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/organizer'
    },
    {
        id   : 'tournamentManagement',
        title: 'Tournament Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/tournamentManagement'
    },
    {
        id   : 'UserManagement',
        title: 'User Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/UserManagement'
    },
    {
        id   : 'jobListing',
        title: 'Job Listing',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/jobListing'
    },
    {
        id   : 'educationalVideos',
        title: 'Educational Videos',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/educationalVideos'
    },
    {
        id   : 'gameCategory',
        title: 'Game & Category Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/gameCategory'
    },
    {
        id   : 'liveStream',
        title: 'Live Stream',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/liveStream'
    },
    {
        id   : 'comment',
        title: 'Comment',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/comment'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
